import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useAuthContext } from "src/hooks";
import { SelectOption } from "src/types";
import {
  GET_DROPPED_MEMBERS,
  GET_MEMBER,
  GET_MEMBERS,
  GET_MEMBER_CARE_PLAN,
  GET_UNASSIGNED_MEMBERS,
} from "../Members/queries";
import {
  DefaultResponse,
  GetOrganizationsResponse,
  GetUserResponse,
  GetUsersInput,
  GetUsersResponse,
  GetUserStatsResponse,
  MutationAssignUserToMemberArgs,
  MutationBulkAssignUsersToMembersArgs,
  MutationChangeUserPasswordArgs,
  MutationDeleteUserArgs,
  MutationLinkRecommendingProviderToOrganizationArgs,
  MutationReactivateUserArgs,
  MutationRetireUserArgs,
  MutationUnassignUserFromMemberArgs,
  MutationUnlinkRecommendingProviderFromOrganizationArgs,
  MutationUpdateUserArgs,
  QueryAvailableUsersByOrganizationIdArgs,
  QueryOrganizationsForUserArgs,
  QueryRecommendingProvidersByOrganizationIdArgs,
  QueryUserArgs,
  QueryUsersArgs,
  QueryUserStatsArgs,
} from "../schemaTypes";
import {
  ASSIGN_USER_TO_MEMBER,
  BULK_ASSIGN_USERS,
  CHANGE_USER_PASSWORD,
  DELETE_USER,
  LINK_RECOMMENDING_PROVIDER_TO_ORGANIZATION,
  REACTIVATE_USER,
  RETIRE_USER,
  SEND_IN_CALL_PING,
  UNASSIGN_USER_FROM_MEMBER,
  UNLINK_RECOMMENDING_PROVIDER_FROM_ORGANIZATION,
  UPDATE_USER,
} from "./mutations";
import {
  GET_AVAILABLE_USERS_BY_ORG_ID,
  GET_RECOMMENDING_PROVIDERS,
  GET_RECOMMENDING_PROVIDERS_BY_ORG_ID,
  GET_USER,
  GET_USERS,
  GET_USERS_STATS,
  GET_USER_AND_ORGS,
} from "./queries";

export const useQueryUsers = (input: GetUsersInput) =>
  useQuery<{ users: GetUsersResponse }, QueryUsersArgs>(GET_USERS, {
    variables: {
      input,
    },
  });

export const useLazyQueryAssignableUserOptions = () => {
  const { selectedOrganizationId } = useAuthContext();
  const [userOptions, setUserOptions] = useState<
    SelectOption<string>[] | undefined
  >(undefined);

  const [lazyQueryUsers, { data: response, loading }] = useLazyQuery<
    { users: GetUsersResponse },
    QueryUsersArgs
  >(GET_USERS, {
    variables: {
      input: {
        organizationId: selectedOrganizationId,
        getDeactivatedUsers: false,
      },
    },
  });

  useEffect(() => {
    if (response?.users.data) {
      setUserOptions(
        response.users.data.map((user) => ({
          label: user.name,
          value: user._id,
        }))
      );
    }
  }, [response]);

  return {
    queryAssignableUsers: lazyQueryUsers,
    assignableUserOptions: userOptions,
    assignableUsersLoading: loading,
  };
};

export const useQueryUserById = (userId: string) =>
  useQuery<{ user: GetUserResponse }, QueryUserArgs>(GET_USER, {
    variables: { userId },
  });

// require userId param, skip if undefined
export const useLazyQueryUserState = () =>
  useLazyQuery<
    { user: GetUserResponse; organizationsForUser: GetOrganizationsResponse },
    QueryUserArgs & QueryOrganizationsForUserArgs
  >(GET_USER_AND_ORGS);

export const useQueryUserState = (userId: string) =>
  useQuery<
    { user: GetUserResponse; organizationsForUser: GetOrganizationsResponse },
    QueryUserArgs & QueryOrganizationsForUserArgs
  >(GET_USER_AND_ORGS, { variables: { userId }, skip: !userId });

export const useQueryUserStats = ({
  organization,
  getDeactivatedUsers,
  before,
  after,
}: {
  organization?: string;
  getDeactivatedUsers?: boolean;
  before?: string;
  after?: string;
}) =>
  useQuery<{ userStats: GetUserStatsResponse }, QueryUserStatsArgs>(
    GET_USERS_STATS,
    {
      variables: { organization, getDeactivatedUsers, before, after },
      fetchPolicy: "network-only",
    }
  );

export const useQueryRecommendingProvidersByOrganizationId = (
  organizationId: string,
  getDeactivated: boolean
) =>
  useQuery<
    { recommendingProvidersByOrganizationId: GetUsersResponse },
    QueryRecommendingProvidersByOrganizationIdArgs
  >(GET_RECOMMENDING_PROVIDERS_BY_ORG_ID, {
    variables: {
      input: {
        organizationId: organizationId,
        getDeactivated: getDeactivated,
      },
    },
    fetchPolicy: "network-only",
  });

export const useQueryRecommendingProviders = (skip?: boolean) =>
  useQuery<{ recommendingProviders: GetUsersResponse }>(
    GET_RECOMMENDING_PROVIDERS,
    { skip }
  );

export const useLazyQueryAvailableUsers = (organizationId: string) =>
  useLazyQuery<
    { availableUsersByOrganizationId: GetUsersResponse },
    QueryAvailableUsersByOrganizationIdArgs
  >(GET_AVAILABLE_USERS_BY_ORG_ID, {
    variables: { organization: organizationId },
    fetchPolicy: "network-only",
  });

export const useMutationUpdateUser = () =>
  useMutation<{ updateUser: GetUserResponse }, MutationUpdateUserArgs>(
    UPDATE_USER,
    {
      refetchQueries: [{ query: GET_USERS }],
    }
  );

export const useMutationChangeUserPassword = () =>
  useMutation<
    { changeUserPassword: GetUserResponse },
    MutationChangeUserPasswordArgs
  >(CHANGE_USER_PASSWORD);

export const useMutationAssignUserToMember = (
  memberId: string,
  organizationId: string
) =>
  useMutation<
    { assignUserToMember: DefaultResponse },
    MutationAssignUserToMemberArgs
  >(ASSIGN_USER_TO_MEMBER, {
    refetchQueries: [
      { query: GET_MEMBER, variables: { memberId } },
      { query: GET_MEMBER_CARE_PLAN, variables: { memberId, organizationId } },
      GET_MEMBERS,
      GET_UNASSIGNED_MEMBERS,
      GET_DROPPED_MEMBERS,
    ],
  });

export const useMutationUnassignUserFromMember = (
  memberId: string,
  organizationId: string
) =>
  useMutation<
    { unassignUserFromMember: DefaultResponse },
    MutationUnassignUserFromMemberArgs
  >(UNASSIGN_USER_FROM_MEMBER, {
    refetchQueries: [
      { query: GET_MEMBER, variables: { memberId } },
      { query: GET_MEMBER_CARE_PLAN, variables: { memberId, organizationId } },
      GET_MEMBERS,
    ],
  });

export const useMutationDeleteUser = () =>
  useMutation<{ deleteUser: GetUserResponse }, MutationDeleteUserArgs>(
    DELETE_USER,
    { refetchQueries: ["GetUsers", "GetMembers"] }
  );

export const useMutationRetireUser = () =>
  useMutation<{ retireUser: GetUserResponse }, MutationRetireUserArgs>(
    RETIRE_USER,
    { refetchQueries: ["GetUsers"] }
  );

export const useMutationReactivateUser = () =>
  useMutation<{ reactivateUser: GetUserResponse }, MutationReactivateUserArgs>(
    REACTIVATE_USER,
    { refetchQueries: ["GetUsers"] }
  );

export const useMutationBulkAssignUsersToMembers = (organizationId: string) =>
  useMutation<
    { bulkAssignUsersToMembers: DefaultResponse },
    MutationBulkAssignUsersToMembersArgs
  >(BULK_ASSIGN_USERS, {
    refetchQueries: [GET_MEMBERS, GET_UNASSIGNED_MEMBERS, GET_DROPPED_MEMBERS],
  });

export const useMutationAssignMemberToUser = () =>
  useMutation<
    { assignUserToMember: DefaultResponse },
    MutationAssignUserToMemberArgs
  >(ASSIGN_USER_TO_MEMBER);

export const useMutationUnassignMemberFromUser = () =>
  useMutation<
    { unassignUserFromMember: DefaultResponse },
    MutationUnassignUserFromMemberArgs
  >(UNASSIGN_USER_FROM_MEMBER);

export const useMutationSendInCallPing = () =>
  useMutation<{ inCallPing: DefaultResponse }, void>(SEND_IN_CALL_PING);

export const useMutationLinkRecommendingProviderToOrganization = () =>
  useMutation<
    { linkRecommendingProviderToOrganization: GetUserResponse },
    MutationLinkRecommendingProviderToOrganizationArgs
  >(LINK_RECOMMENDING_PROVIDER_TO_ORGANIZATION, {
    refetchQueries: [GET_RECOMMENDING_PROVIDERS_BY_ORG_ID],
  });

export const useMutationUnlinkRecommendingProviderFromOrganization = () =>
  useMutation<
    { unlinkRecommendingProviderFromOrganization: GetUserResponse },
    MutationUnlinkRecommendingProviderFromOrganizationArgs
  >(UNLINK_RECOMMENDING_PROVIDER_FROM_ORGANIZATION, {
    refetchQueries: [GET_RECOMMENDING_PROVIDERS_BY_ORG_ID],
  });
