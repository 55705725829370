import { useState, useRef, useMemo } from "react";
import { FormikProps, FormikHelpers } from "formik";
import {
  Grid,
  Textarea,
  Select,
  MultiSelect,
  TextInput,
  Stack,
} from "@mantine/core";
import { useQueryNoteTemplates } from "src/graphql/NoteTemplate";
import { useMutationCreateNote } from "src/graphql/Note";
import { Note, NoteTemplate, usePaginatedQueryDomains } from "src/graphql";
import toast from "src/libs/toast";

type CreateNoteFormValues = {
  title: string;
  content: string;
  additionalFields: Record<string, string>;
  templateId?: string;
  domainIds?: string[];
};

type NoteFormFieldsProps = {
  values: CreateNoteFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void; // eslint-disable-line
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void; // eslint-disable-line
  templateOptions: { label: string; value: string }[];
  templatesById: Record<string, NoteTemplate>;
  templatesLoading: boolean;
  domainsData: { value: string; label: string }[];
};

export const NoteFormFields = ({
  values,
  handleChange,
  setFieldValue,
  templateOptions,
  templatesById,
  templatesLoading,
  domainsData,
}: NoteFormFieldsProps) => {
  const selectedTemplate = templatesById[values.templateId ?? ""];

  return (
    <Stack spacing="md">
      {/* Template Selection */}
      <Select
        label="Template"
        name="templateId"
        value={values.templateId}
        data={templateOptions}
        placeholder="Select a template..."
        clearable
        onChange={(selected) => {
          setFieldValue("templateId", selected);
          if (selected) {
            const template = templatesById[selected];
            setFieldValue("title", template?.title ?? "");
            setFieldValue(
              "domainIds",
              template?.domains?.map((domain) => domain._id) ?? null
            );
          } else {
            setFieldValue("title", "");
            setFieldValue("domainIds", []);
            setFieldValue("additionalFields", {});
            setFieldValue("content", "");
          }
        }}
        disabled={templatesLoading}
      />

      {/* Title */}
      <TextInput
        label="Note Title"
        name="title"
        value={values.title}
        onChange={handleChange}
      />

      {/* Domain Selection */}
      <MultiSelect
        label="Select Domains"
        placeholder="Select a domain..."
        data={domainsData}
        disabled={Boolean(values.templateId && selectedTemplate?.domains)}
        name="domainIds"
        clearable
        onChange={(selectedOptions) => {
          setFieldValue("domainIds", selectedOptions ?? []);
        }}
        value={values.domainIds}
      />

      {/* Additional fields */}
      <Grid>
        {selectedTemplate?.additionalFields?.length ? (
          selectedTemplate.additionalFields.map((field) => (
            <Grid.Col span={12} key={field.key}>
              <Textarea
                label={field.key}
                name={`additionalFields.${field.key}`}
                value={values.additionalFields[field.key]}
                onChange={handleChange}
                autosize
                minRows={2}
                maxRows={4}
              />
            </Grid.Col>
          ))
        ) : (
          <Grid.Col span={12}>
            <Textarea
              label="Content"
              name="content"
              value={values.content}
              onChange={handleChange}
              required
              autosize
              minRows={4}
            />
          </Grid.Col>
        )}
      </Grid>
    </Stack>
  );
};

interface UseNoteFormProps {
  organizationId: string;
  userId: string;
  activityId?: string;
  memberId?: string;
  existingNote?: Note | null;
  externalResourceId?: string;
  externalResourceContactId?: string;
  multiMemberActivity: boolean;
  createNoteForAllMembersInActivity?: boolean;
  afterUpdateSuccess?: () => void;
  onClose: () => void;
}

export const useNoteForm = ({
  organizationId,
  userId,
  activityId,
  memberId,
  existingNote,
  externalResourceId,
  externalResourceContactId,
  multiMemberActivity,
  createNoteForAllMembersInActivity = false,
  afterUpdateSuccess,
  onClose,
}: UseNoteFormProps) => {
  const formikRef = useRef<FormikProps<CreateNoteFormValues>>(null);
  const [submissionPending, setSubmissionPending] = useState(false);
  const [createForAllMembersInActivity, setCreateForAllMembersInActivity] =
    useState(createNoteForAllMembersInActivity);

  // Fetch note templates
  const { loading: templatesLoading, data: templatesResponse } =
    useQueryNoteTemplates({
      organizationId,
    });

  const { data: domainsData } = usePaginatedQueryDomains({
    organizationId,
  });

  const [mutationCreateNote] = useMutationCreateNote();

  // Prepare options and map templates by ID
  const { templateOptions, templatesById } = useMemo(() => {
    const templates = templatesResponse?.noteTemplates.data ?? [];
    return {
      templateOptions: wrapGroupedTemplateOptions(templates),
      templatesById: templates.reduce(
        (byId, template) => ({ ...byId, [template._id]: template }),
        {} as Record<string, NoteTemplate>
      ),
    };
  }, [templatesResponse?.noteTemplates.data]);

  const getCustomFieldValues = (
    existingNote: Note | null | undefined,
    customFields: { key: string }[]
  ) => {
    return Object.fromEntries(
      customFields.map((field) => [
        field.key,
        existingNote ? parseContent(existingNote.content, field.key) : "",
      ])
    );
  };

  const initialValues = useMemo(
    () => ({
      templateId: existingNote?.noteTemplateId ?? undefined,
      title: existingNote?.title ?? "",
      domainIds: existingNote?.domains?.map((domain) => domain._id) ?? [],
      content: existingNote?.content ?? "",
      additionalFields: existingNote?.noteTemplateId
        ? getCustomFieldValues(
            existingNote,
            templatesById[existingNote.noteTemplateId]?.additionalFields || []
          )
        : {},
    }),
    [existingNote, templatesById]
  );

  const handleSubmit = async (
    values: CreateNoteFormValues,
    formikHelpers: FormikHelpers<CreateNoteFormValues>
  ) => {
    formikHelpers.setStatus({});
    setSubmissionPending(true);

    const selectedTemplate = templatesById[values.templateId ?? ""];

    try {
      let content;

      // Check if there are additionalFields in the template, if not, use the values.content directly
      if (selectedTemplate?.additionalFields?.length) {
        content = selectedTemplate.additionalFields
          .map(
            (field) =>
              `${field.key}: ${values.additionalFields[field.key] ?? ""}`
          )
          .join("\n");
      } else {
        // No template or no additional fields, use user-provided content
        content = values.content;
      }

      const input = {
        content,
        noteTemplateId: values.templateId ?? undefined,
        title: values.title?.trim() ?? undefined,
        domainIds: values.domainIds ?? [],
        activityId,
        organizationId,
        externalResourceId,
        externalResourceContactId,
        authorId: userId,
        ...(memberId ? { memberId } : {}),
        familyId: existingNote?.familyId ?? existingNote?._id ?? undefined,
        createForAllMembersInActivity,
      };

      const res = await mutationCreateNote({
        variables: { input },
      });

      if (res.errors?.length || res.data?.createNote?.success === false) {
        const errorMessage =
          res.data?.createNote?.message ??
          "Oops! Something went wrong. Please try again.";
        formikHelpers.setStatus({ errorMessage });
        toast.error(errorMessage);
      } else {
        afterUpdateSuccess?.();
        onClose();
      }
    } catch {
      formikHelpers.setStatus({
        errorMessage: "Oops! Something went wrong. Please try again.",
      });
    } finally {
      setSubmissionPending(false);
    }
  };

  return {
    formikRef,
    initialValues,
    handleSubmit,
    templateOptions,
    templatesById,
    templatesLoading,
    domainsData,
    submissionPending,
    setCreateForAllMembersInActivity,
    createForAllMembersInActivity,
  };
};

/**
 * Wraps template options in a grouped SelectOption type
 */
const wrapGroupedTemplateOptions = (templates: NoteTemplate[]) => {
  return templates.map((template) => ({
    label: template.title,
    value: template._id,
  }));
};

const parseContent = (content: string, fieldLabel: string): string => {
  // Use regex to capture the answer for a specific field label
  // For regex details, visit: https://regex101.com/r/g5Sjfp/1
  const regex = new RegExp(
    `${fieldLabel.replace(
      /[-\\^$*+?.()|[\]{}]/g,
      "\\$&"
    )}:\\s*([\\s\\S]*?)(?=\\n\\w+:|$)`, // Match until the next label or end of content
    "g"
  );

  // Execute the regex using exec()
  const match = regex.exec(content);

  // If a match is found, return the content after the label
  return match ? match[1].trim() : "";
};
