import { Stack } from "@mantine/core";
import { useMemo, useState } from "react";
import toast from "src/libs/toast";
import { CreateNoteInput, GetNotesInput, Note } from "src/graphql";
import {
  useMutationCreateNote,
  useMutationUpdateNote,
  useQueryNotes,
} from "src/graphql/Note";
import { useAuthContext } from "../../hooks/useAuthContext";
import { NotesFilters } from "./NotesFilters";
import { NotesList } from "./NotesList";
import { wrapSelectOption, isNot } from "src/utils";

type NotesHookOptions = {
  listOptions: Omit<GetNotesInput, "organizationId">;
  createOptions: Omit<
    CreateNoteInput,
    "content" | "authorId" | "organizationId"
  >;
};

const useNotes = ({ listOptions, createOptions }: NotesHookOptions) => {
  const { currentUser, selectedOrganizationId } = useAuthContext();

  const notesQuery = useQueryNotes({
    organizationId: selectedOrganizationId,
    ...listOptions,
  });

  const [mutationCreateNote] = useMutationCreateNote();
  const [mutationUpdateNote] = useMutationUpdateNote();

  const notes = useMemo(() => notesQuery.data?.notes.data ?? [], [notesQuery]);

  const redactNote = async (note: Note) => {
    try {
      const response = await mutationUpdateNote({
        variables: {
          input: {
            noteId: note._id,
            redacted: !note.redacted,
            organizationId: selectedOrganizationId,
          },
        },
      });

      if (!response.data?.updateNote.success)
        throw new Error(response.data?.updateNote.message);
    } catch (error) {
      toast.error(
        error instanceof Error && error.message
          ? error.message
          : "Something went wrong while udpating note"
      );
    }
  };

  const createNote = async (
    content: string,
    createForAllMembersInActivity?: boolean,
    familyId?: string
  ) => {
    try {
      const response = await mutationCreateNote({
        variables: {
          input: {
            ...createOptions,
            authorId: currentUser._id,
            organizationId: selectedOrganizationId,
            content,
            createForAllMembersInActivity:
              createForAllMembersInActivity ?? false,
            familyId,
          },
        },
      });

      if (!response.data?.createNote.success)
        throw new Error(response.data?.createNote.message);
    } catch (error) {
      toast.error(
        error instanceof Error && error.message
          ? error.message
          : "Something went wrong while creating the note"
      );
    }
  };

  return { notes, redactNote, createNote };
};

type NotesProps = NotesHookOptions & {
  hideAddButton?: boolean;
  hideNoNotesPlaceholder?: boolean;
  multiMemberActivity?: boolean;
  readonly?: boolean;
  showFilters?: boolean;
  handleEditClick: (note: Note) => void;
};
export const Notes = (options: NotesProps) => {
  const { notes, redactNote } = useNotes(options);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const groupedNotes = notes.reduce((acc, note) => {
    const familyId = note.familyId || note._id;
    if (!acc[familyId]) {
      acc[familyId] = [];
    }
    acc[familyId].push(note);
    return acc;
  }, {} as Record<string, Note[]>);

  const filteredGroupedNotes = Object.keys(groupedNotes)
    .map((familyId) => {
      const notesInFamily = groupedNotes[familyId];
      const sortedNotesInFamily = [...notesInFamily].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

      // Function to check if a note matches the search, activity, and domain filters
      const matchesAllConditions = (note: Note) => {
        const matchesSearchQuery =
          searchQuery.length === 0 || // No search query, match everything
          note.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
          note?.authorName?.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesActivity =
          !selectedActivity.length || // No activity selected, match everything
          (note.activityTitle && selectedActivity.includes(note.activityTitle));

        const matchesDomain =
          !selectedDomain.length || // No domain selected, match everything
          note.domains?.some(
            (domain) => domain.title && selectedDomain.includes(domain.title) // Check if any of the note's domains match the selected domains
          );
        // Return true if the note matches all three conditions
        return matchesSearchQuery && matchesActivity && matchesDomain;
      };

      // Filter notes in the family (including previous versions) that match all conditions
      const matchingVersions = sortedNotesInFamily.filter(matchesAllConditions);

      // If there are no matching versions, return null
      if (matchingVersions.length === 0) return null;

      const sortedMatchingVersionsDesc = matchingVersions.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      const latestMatchingNoteDesc = sortedMatchingVersionsDesc[0];
      const previousMatchingVersionsDesc = sortedMatchingVersionsDesc.slice(1);

      const isRedacted = notesInFamily.some((note) => note.redacted);

      return {
        latestNote: latestMatchingNoteDesc,
        previousVersions: previousMatchingVersionsDesc,
        versions: sortedNotesInFamily,
        isRedacted,
      };
    })
    .filter(isNot(null))
    .sort((a, b) => {
      return sortOrder === "desc"
        ? new Date(b.latestNote.createdAt).getTime() -
            new Date(a.latestNote.createdAt).getTime()
        : new Date(a.latestNote.createdAt).getTime() -
            new Date(b.latestNote.createdAt).getTime();
    });

  // Get unique activities for filtering
  const activityOptions = Array.from(
    new Set(notes.map((note) => note.activityTitle).filter(Boolean))
  ).map((activityTitle) => wrapSelectOption(activityTitle ?? ""));

  // Get unique domains for filtering
  const domainOptions = Array.from(
    new Set(
      notes
        .flatMap((note) => note.domains?.map((domain) => domain.title))
        .filter(Boolean)
    )
  ).map((domainTitle) => wrapSelectOption(domainTitle ?? ""));

  return (
    <Stack spacing="xs">
      {options.showFilters && (
        <NotesFilters
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedDomain={selectedDomain}
          setSelectedDomain={setSelectedDomain}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          sortOrder={sortOrder}
          toggleSortOrder={toggleSortOrder}
          activityOptions={activityOptions}
          domainOptions={domainOptions}
        />
      )}
      <NotesList
        filteredGroupedNotes={filteredGroupedNotes}
        redactNote={redactNote}
        handleEditClick={options.handleEditClick}
      />
    </Stack>
  );
};
