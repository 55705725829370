import { GroupFields } from "../MemberGroup/types";
import { CallRoutingConfigurationFields } from "../CallRoutingConfiguration/types";
import { LockingReferenceFields } from "../Common/types";

export const BillingProviderFields = `
    _id
    address
    city
    nationalProviderIdentifier
    organizationNameORProviderLastName
    phone
    providerFirstName
    state
    taxIdentificationNumber
    zip
    organizationId
    label
    isDefault
    type
`;

export const CostConfigurationFields = `
    _id
    procedure
    description
    unitPeriod
    cost
    modifiers
    userProviderRequired
    recommendingProviderRequired
    limits {
        _id
        label
        period
        periodUnit
        units
    }
`;

export const InsuranceTransactionFields = `
    _id
    stripeId
    stripeCustomerId
    insurances
    amount
    resolvedAmount
    reference
    senderName
    senderNetwork
    date
`;

export const InsuranceConfigurationFields = `
    _id
    inNetwork
    archived
    familyId
    insurance
    generator
    deliveryMethod
    deliveryEmail
    claimType

    submitterId
    submitterIdType
    submitterFirstName
    submitterOrganizationNameOrLastName

    payerId
    payerIdType
    payerFirstName
    payerOrganizationNameOrLastName

    stripeCustomerId
    swiftSupportedNetworks
    swiftAccountNumber
    swiftCode
    swiftBankName
    abaSupportedNetworks
    abaAccountNumber
    abaRoutingNumber
    abaBankName

    coverageCheckPayerId
    costs {${CostConfigurationFields}}
    billingProvider {${BillingProviderFields}}
    submitRecommendingProviderWithClaim
`;

export const InsuranceConfigurationSummaryFields = `
    _id
    insurance
    inNetwork
    familyId
    archived
    generator
    deliveryMethod
`;

export const MemberProfileSettingsFields = `
    memberPhoneticHidden
    memberPreferredNameHidden
    memberDOBHidden
    memberGenderHidden
    memberPronounsHidden
    memberRaceHidden
    memberEthnicityHidden
    memberLanguagesHidden
    memberSexualOrientationHidden
    memberSexHidden
    memberPregnancyHidden
    memberPrimaryInsuranceHidden
    memberSecondaryInsuranceHidden
    memberOrgGroupsHidden
    memberAddressHidden
    memberLinkedContactsHidden
    memberSocialSecurityNumberHidden
    memberMaidenNameOrAliasHidden
    memberMedicalRecordNumberHidden
    memberHighestLevelOfEducationHidden
    memberEmploymentStatusHidden
    memberEmployerNameHidden
    memberMaritalStatusHidden
    memberPeopleInHouseholdHidden
    memberChildrenInHouseholdHidden`;

export const OnboardingSurveyFields = `
    _id
    enabled
    flowTemplate
    activityTemplate
    link
    title
`;

export const OrganizationFields = `
    _id
    status
    title
    groups {
        ${GroupFields}
    }
    members
    users
    domains {
        _id
        title
        description
    }
    description
    defaultAnsweringUserIds
    callerId
    mfaPolicy
    seats
    subscriptionPlan
    subscriptionDueDate
    subscriptionIsActive
    stripeCustomerId
    stripeCustomPriceId
    stripeCustomPlanName
    stripePayoutAccountEnabled
    stripePayoutAccountId
    stripePayoutRequirementsStatus
    stripePayoutRequirementsDeadline
    claimsPlatformFeePercentage
    timeTrackingEnabled
    callRecordingEnabled
    showMemberOverviewTab

    diagnosisCodes {
        code
        description
    }

    placeOfServiceCodes 

    analyticsConfiguration {
        analyticsConfigSummaryMetrics
        analyticsConfigCallCenterMetrics
        analyticsConfigActivities
        analyticsConfigGoals
        analyticsConfigDemographics
        analyticsConfigCarePathways
        analyticsConfigExternalResources
        customMetrics {
            title
            chart
            dataId
        }
    }

    memberOverviewConfiguration {
        title
        type
        metrics {
            label
            dataPointTemplateId
        }
    }
    
    billingEnabled
    billingRecommendingProviderEnabled
    billingConsentTemplateId
    billingRenderingProvider { ${BillingProviderFields} }
    billingInsuranceConfigurations { ${InsuranceConfigurationFields} }

    activatedIntegrations
    ehrNoteProviderID
    ehrNoteProviderIDType
    ehrNoteProviderFirstName
    ehrNoteProviderLastName
    ehrNoteProviderCredential
    
    episodesOfCareEnabled

    userGroups {
        _id
        users {
            _id
            name
        }
        title
        lockingReferences {
            ${LockingReferenceFields}
        }
    }

    callRoutingConfiguration {
        ${CallRoutingConfigurationFields}
    }

    memberProfileSettings {
        ${MemberProfileSettingsFields}
    }
    onboardingMemberProfileSettings {
        ${MemberProfileSettingsFields}
    }
    billingProviders {
        ${BillingProviderFields}
    }
    onboardingSurveys {
        ${OnboardingSurveyFields}
    }
    carePlansEnabled
    domains {
        _id
        title
        description
    }
`;

export const ActiveMembersByMonthFields = `
    month
    organizationId
    activeMemberCount
`;

export const ActiveMembersThisMonthFields = `
    activeMemberCount
`;

export const MemberGoalStatsFields = `
    inProgress
    complete
    memberCount
`;

export const CompletedActivitiesByTypeByMonthFields = `
    count
    month
    activityId
    activityName
`;

export const CompletedGoalsByTypeByMonthFields = `
    count
    month
    templateId
    templateName
`;

export const UserGroupFields = `
    _id
    title
    users {
        _id
        name
    }
    lockingReferences {
        ${LockingReferenceFields}
    }
`;
