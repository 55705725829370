import { ActivityFields } from "../Activity/types";
import { memberFields } from "../Members/types";
import {
  InsuranceConfigurationSummaryFields,
  InsuranceTransactionFields,
} from "../Organization/types";
import { UserFields } from "../Users/types";

export const BillFields = `
    _id
    member { ${memberFields} }
    insurance { ${InsuranceConfigurationSummaryFields} }
    activities { ${ActivityFields} }
    status
    statusMessages
    invoicedAmount
    paidAmount
    trace
    resolvedTransaction { ${InsuranceTransactionFields} }
    approvedBy { ${UserFields} }
    approvedAt
    filePath
    generatedId
    prevGeneratedIds
    deliveredAt
    generatedAt
    transferredAt
    reIssue
    originalRefId
`;

export const ProviderReportFields = `
    _id
    member { ${memberFields} }
    generatedId
    generatedAt
    billingProviderNPI
    renderingProviderNPI
`;

export const BillBatchFields = `
    _id
    deliveryKind
    status
    bills {
        _id
        status
    }
    availityId
    insuranceCompanies
    createdAt
    updatedAt
`;
