export const NoteFields = `
  _id
  content
  redacted
  organizationId

  authorId
  authorName

  memberId
  externalResourceId
  externalResourceContactId
  activityId

  createdAt
  updatedAt
  noteTemplateId
  domains {
    _id
    title
  }
  familyId
  noteTemplateTitle
  activityTitle
  title
`;
