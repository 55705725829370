export const NoteTemplateFields = `
  _id
  title
  content
  additionalFields {
    key
    value
  }
  organizationId
  createdAt
  updatedAt
  domains {
    _id
    title
  }
  `;
